<template>
    <main-template>
        <template v-slot:headerLottieDark>
            <dotlottie-player :src="getDarkLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
        </template>
        <template v-slot:headerLottieLight>
            <dotlottie-player :src="getLightLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal" ></dotlottie-player>
        </template>
        <template v-slot:header>Hub</template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }" class="flex flex-grow overflow-hidden">
                <div class="flex w-full overflow-hidden">
                    <div class="flex w-full h-full p-2 overflow-hidden">
                        <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-y-auto">
                            <loading v-if="!response.body || !response.body.actions.filter(action => action.name !== 'filter'  && !action.fields.isEmpty()).first()"></loading>
                            <template v-else>
                                <div class="p-2 h-full">
                                    <div class="grid grid-cols-1 gap-2 h-full">
                                        <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2">
                                            <h1 class="flex justify-center text-lg">Generate Quote</h1>
                                            <p class="my-2 text-sm italic"></p>
                                            <div class="flex justify-center">
                                                <div v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter'  && !action.fields.isEmpty())"
                                                     class="mr-1">
                                                    <generate-quote-form v-if="actionWithFields[0] === 'generate-quote'"
                                                                         :action="actionWithFields[1]"
                                                                         :actionKey="actionWithFields[0]"
                                                                         :onSuccessPath="$router.currentRoute.fullPath"
                                                                         additionalButtonClasses="my-2 w-full"
                                                                         :response="response"
                                                    ></generate-quote-form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import GenerateQuoteForm from "@/v2/components/forms/GenerateQuoteForm.vue";
import MainTemplate from "@/v3/templates/MainTemplate.vue";
import Loading from "@/components/Loading";
import PageHeader from "@/v2/components/Header";

export default {
    name: "QuoteGenerator",
    data() {
        return {
            loading: false,
            pdfLoading: false,
            showDownloadForm: false,
            displayOnSiteIdDetails: false,
            onSiteIdResource: null
        }
    },
    components: {
        GenerateQuoteForm,
        MainTemplate,
        PageHeader,
        FetchSirenRoot,
        Loading
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToClient(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        setOnSiteId(response){
            this.onSiteIdResource = response.body.entities.filter(entity => entity.rels.contains('on-site-id')).first().entity;
            return this.onSiteIdResource;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_light"
        },
    }
}
</script>

<style scoped>

</style>
