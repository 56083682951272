<template>
    <tbody>
    <new-add-price-order-item @removeItem="removeItem(key)" ref="newOrderItem" :response="response" v-for="value,key in qty"
                    :key="key" :keyProp="key" :defaultValues="value" :scope="getScope(key)" v-if="value !== null"
                    :action="response" @input="change" v-model="itemPrices[key]" :serviceFeeProp="serviceFee"></new-add-price-order-item>

    <tr class="text-center">
        <td colspan="100">
            <div class="grid grid-cols-3 p-3">
                <button @click="addNewItem()" data-cy="add-item"
                        class="col-start-2 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition duration-150 ease-in-out">
                    + New Order Item
                </button>

            </div>
        </td>
    </tr>
    </tbody>
</template>

<script>
    import NewOrderItem from './NewOrderItem';
    import UploadItemsFromCsv from './UploadItemsFromCsv';
    import NewAddPriceOrderItem from "@/components/NewAddPriceOrderItem.vue";
    import OrderItemAddPriceRow from "@/components/OrderItemAddPriceRow.vue";

    export default {
        inject: ["$validator"],
        components: {
            OrderItemAddPriceRow,
            NewAddPriceOrderItem,
            NewOrderItem,
            UploadItemsFromCsv
        },
        props: {
            response: {
                type: Object
            },
            serviceFee:{
                type: Number,
                default: 3
            }
        },
        data() {
            return {
                qty: [],
                itemPrices: []
            }
        },
        computed: {
        },
        methods: {
            addImportedItems(obj) {
                obj.forEach(object => {
                    this.qty.push(object);
                });
                this.$emit('change');
                this.$store.dispatch(
                    "setMessage",
                    "CSV imported, please check the rows & save the quote to persist the changes."
                );
            },
            removeItem(key) {
                this.$set(this.qty, key, null);
                this.itemPrices.splice(key, 1);
                this.change();
            },
            async addNewItems() {
                let addNewItemPromises = [];
                if (this.$refs.newOrderItem) {
                    for (const { index,row } of this.$refs.newOrderItem.map((row, index) => ({ index,row }))){
                        console.log(index);
                       addNewItemPromises.push(await row.create().catch(error => {
                            throw {
                                error: error,
                                scope: this.getScope(index)
                            };
                        }));
                    }
                }
                return addNewItemPromises;
            },
            getScope(index) {
                return 'new-order-item-row-' + index;
            },
            addNewItem() {
                this.qty.push({});
                this.$emit('change');
            },
            change(){
                console.log('changed');
                this.$emit('change');
                this.$emit('input', this.itemPrices);
                console.log(this.itemPrices);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
