<template>
    <tr>
        <template ref="newItem">
            <td class="px-3 py-4 whitespace-nowrap border-b border-v3-gray-900 border-opacity-10 dark:border-white dark:border-opacity-10 text-sm leading-5 text-v3-gray-800 dark:text-v3-gray-200 align-top">
                <property-or-autocomplete-field ref="description" @suggestionSelected="descriptionSuggestionChanged" :scope="scope"
                                                :searchAction="searchAction" :action="newItemAction"
                                                fieldId="description" propertyKey="description"
                                                v-model="newItemActionData['description']" @input="descriptionUpdated"></property-or-autocomplete-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap border-b border-v3-gray-900 border-opacity-10 dark:border-white dark:border-opacity-10 text-sm leading-5 text-v3-gray-800 dark:text-v3-gray-200 align-top">
                <property-or-autocomplete-field ref="code" @suggestionSelected="codeSuggestionChanged" :scope="scope"
                                                :searchAction="searchAction" :action="newItemAction" fieldId="code"
                                                propertyKey="code"
                                                v-model="newItemActionData['code']" @input="codeUpdated"></property-or-autocomplete-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap border-b border-v3-gray-900 border-opacity-10 dark:border-white dark:border-opacity-10 text-sm leading-5 text-v3-gray-800 dark:text-v3-gray-200 align-top">
                <property-or-field ref="next" :scope="scope" :action="newItemAction" fieldId="due_date"
                                   propertyKey="due_date" v-model="newItemActionData['due_date']" @input="dueDateUpdated"></property-or-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap border-b border-v3-gray-900 border-opacity-10 dark:border-white dark:border-opacity-10 text-sm leading-5 text-v3-gray-800 dark:text-v3-gray-200 align-top">
                <property-or-field :scope="scope" :action="newItemAction" fieldId="quantity" propertyKey="quantity"
                                   v-model="newItemActionData['quantity']" @input="quantityUpdated"></property-or-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap border-b border-v3-gray-900 border-opacity-10 dark:border-white dark:border-opacity-10 text-sm leading-5 text-v3-gray-800 dark:text-v3-gray-200 align-top">
                <property-or-field :scope="scope" :action="newItemAction" fieldId="unit" propertyKey="unit"
                                   v-model="newItemActionData['unit']" @input="unitUpdated"></property-or-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap border-b border-v3-gray-900 border-opacity-10 dark:border-white dark:border-opacity-10 text-sm leading-5 text-v3-gray-800 dark:text-v3-gray-200">
                <gbp-input class="w-28" :field="{name: 'price_in', title: 'Price In', type: 'number', validation: null}" v-model="items.price_in" @input="priceChanged"></gbp-input>
            </td>

            <td class="px-3 py-4 whitespace-nowrap border-b border-v3-gray-900 border-opacity-10 dark:border-white dark:border-opacity-10 text-sm leading-5 text-v3-gray-800 dark:text-v3-gray-200">
                £{{(items.price_out/100).toFixed(2)}}
            </td>
            <td class="px-3 py-4 whitespace-nowrap border-b border-v3-gray-900 border-opacity-10 dark:border-white dark:border-opacity-10 text-sm leading-5 text-v3-gray-800 dark:text-v3-gray-200 align-top">
                <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem();">remove</span>
            </td>
        </template>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from '../mixins/orderItemsUpdate.js';

    export default {
        inject: ["$validator"],
        mixins: [orderItemsUpdate],
        data() {
            return {
                isSuccessfullyProcessed: false,
                items: []
            }
        },
        mounted(){
            this.items = {description: this.newItemActionData['description'],
                code: this.newItemActionData['code'],
                due_date: this.newItemActionData['due_date'],
                quantity: this.newItemActionData['quantity'],
                unit: this.newItemActionData['unit'],
                price_in: 0,
                price_out:0};
            this.serviceFee = this.response.body.properties.get('service_fee') ? (this.response.body.properties.get('service_fee') / 100) + 1 : (this.serviceFeeProp / 100) + 1;
        },
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            response: {
                type: Object,
            },
            scope: {
                type: String
            },
            keyProp: {
                type: Number
            },
            defaultValues: {
                type: Object,
                default: {}
            },
            serviceFeeProp:{
                type: Number,
                default: 3
            }
        },
        computed: {
            newItemAction() {
                return this.response.body.actions.filter(action => action.name === 'add-new-item').first();
            },
            searchAction() {
                return this.response.body.actions.filter(action => action.name === 'search-products').first();
            },
            newItemActionData() {
                if (!this.newItemAction.fields) {
                    return {};
                }
                var payload = {};
                this.newItemAction.fields.forEach(function (field) {
                    if (this.defaultValues[field.name]) {
                        payload[field.name] = this.defaultValues[field.name]
                    } else if (field.value !== undefined) {
                        payload[field.name] = field.value;
                    }
                }, this);
                return payload;
            },
        },
        methods: {

            removeItem() {
                this.$emit('removeItem', this.keyProp);
                this.items= {};
            },
            descriptionSuggestionChanged(value)
            {
                let id = this.updateCode(value);
                if(id) {
                    this.newItemActionData['product_id'] = id;
                }
            },
            codeSuggestionChanged(value)
            {
                let id = this.updateDescription(value);
                if(id) {
                    this.newItemActionData['product_id'] = id;
                }
            },
            descriptionUpdated(value)
            {
                this.items.description = value;
            },
            codeUpdated(value)
            {
                this.items.code = value;
            },
            dueDateUpdated(value)
            {
                this.items.due_date = value;
            },
            quantityUpdated(value)
            {
                this.items.quantity = value;
                this.items.price_out = (this.items.price_in * parseInt(this.items.quantity) * this.serviceFee);
            },
            unitUpdated(value)
            {
                this.items.unit = value;
            },
            priceChanged(value){
                let qty = this.items.quantity;
                this.priceOut = (value * parseInt(qty) * this.serviceFee);
                this.items.price_out = (value * parseInt(qty) * this.serviceFee);
                console.log(value, qty, this.serviceFee)
                this.$emit('change');
            },
        },
        watch: {
            items: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
